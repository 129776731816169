<template>
  <div>
    <div class="column" style="margin-bottom:0" v-loading="models.loading">
      <div class="flex-row align-items-center margin-bottom-10">
        <div class="margin-right-10">预测更新</div>
      </div>
      <el-table
        ref="datatable"
        :height="$root.docHeight-205"
        size="medium"
        :data="models.list"
      >
        <div slot="empty"><el-empty :image-size="150"></el-empty></div>
        <el-table-column label="产品" prop="productName"></el-table-column>
        <el-table-column label="模型自估预测更新状态">
          <template slot-scope="props"><span :class="{'color-red':!props.row.ai}">{{ props.row.ai?'已更新':'未更新' }}</span></template>
        </el-table-column>
        <el-table-column label="卓创赋值预测更新状态">
          <template slot-scope="props"><span :class="{'color-red':!props.row.sci}">{{ props.row.sci?'已更新':'未更新' }}</span></template>
        </el-table-column>
        <el-table-column label="滨化赋值预测更新状态">
          <template slot-scope="props"><span :class="{'color-red':!props.row.befar}">{{ props.row.befar?'已更新':'未更新' }}</span></template>
        </el-table-column>
        <!-- <el-table-column label="操作" width="140">
          <template slot-scope="props">
            <span v-if="!props.row.befar" class="link-text" @click="updateBefarModel(props.row)">更新滨化赋值预测</span>
          </template>
        </el-table-column> -->
      </el-table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'modelManageModelUpdate',
  components:{
  },
  props:{
    modelFreq:{
      type:String,
      default:'week'
    }
  },
  data (){
    return{
   
      models: {
        loading:false,
        list:[]
      },
      
      
    }
  },
  computed:{
    
  },
  created(){
    this.getList()
  },
  mounted(){

  },
  methods:{
    
    //获取列表信息
    getList(){
      this.models.loading=true
      let request=this.$instance.get('/model/getForecastUpdate',{
          params: {
           ...this.$store.state.basicParams,
           modelFreq:this.modelFreq,
           moduleCode:'mo_xing_guan_li'
          },
      })
      request.then(res=>{
        this.models.loading=false
        if(res.data.code==0){
          this.models.list=res.data.info
        }else{
          this.models.list=[]
        }
      })
      request.catch(()=>{
        this.models.loading=false
      })
      return request
    },
    //更新滨化预测
    updateBefarModel(item){
      if(!item.publishId){
        this.$message({message:'因子未赋值，请赋值后更新预测',type:'warning',center:true,offset: Math.ceil(document.documentElement.clientHeight/2.4)})
        return false;
      }
      let request=this.$instance.get('/model/updateBefarModel',{
          params: {
           ...this.$store.state.basicParams,
           modelFreq:this.modelFreq,
           publishId:item.publishId,
           targetId:item.targetId
          },
      })
      request.then(res=>{
        if(res.data.code==0){
          this.$message({message:'操作成功',type:'success',center:true,offset: Math.ceil(document.documentElement.clientHeight/2.4)})
        }
      })
      request.catch(()=>{
      })
      return request
    },
  },
  
  watch: {
    "modelFreq" : {
      handler:function(){
        this.getList()
      },
      deep:true
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style  lang="scss" scoped>

 
</style>
